<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="my-point" v-loading="loading">
			<div class="member-point">
				<div class="title">我的红包</div>
				<div class="num">{{ memberInfo.redpacket_money ?  parseFloat(memberInfo.redpacket_money).toFixed(2) : 0 }}</div>
			</div>
			<div class="detail">
				<el-table :data="redRecordList" border>
					<el-table-column prop="type_name" label="来源" width="150"></el-table-column>
					<el-table-column prop="Num" label="数量" width="150"></el-table-column>
					<el-table-column prop="remark" label="详细说明"></el-table-column>
					<el-table-column prop="time" label="时间" width="180"></el-table-column>
				</el-table>
			</div>
			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					prev-text="上一页"
					next-text="下一页"
					:current-page.sync="listInfo.page"
					:page-size.sync="listInfo.page_size"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { redRecordList } from '@/api/member/red_record_list';
import { memberInfo } from '@/api/member/index';
export default {
	name: 'my_point',
	components: {},
	data: () => {
		return {
			listInfo: {
				page: 1,
				page_size: 10,
				account_type: 'redpacket_money'
			},
			pointList: [],
			memberPoint: {
				point: 0
			},
			total: 0,
			loading: true,
			yes: true,
			redRecordList: [], //红包使用记录
			memberInfo: {}
		};
	},
	created() {
		this.getMemberInfo(), this.getredRecordList();
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false;
		}, 300);
	},
	methods: {
		//获取  会员基础信息
		getMemberInfo() {
			memberInfo()
				.then(res => {
					if (res.data && res.code == 0) {
						this.memberInfo = res.data;
					} else {
						this.$message.error(err.message);
					}
				})
				.catch(err => {
					console.log(err.message);
				});
				this.loading = false
		},
		getredRecordList() {
			redRecordList(this.listInfo)
				.then(res => {
					if (res.code == 0 && res.data) {
						this.redRecordList = res.data.list;
						this.total = res.data.count;
						this.redRecordList.forEach(item => {
							item.time = this.$util.timeStampTurnTime(item.create_time);
							item.Num = item.account_data > 0 ? '+' + parseFloat(item.account_data).toFixed(2) : parseFloat(item.account_data).toFixed(2);
						});
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		handlePageSizeChange(num) {
			this.listInfo.page_size = num;
			this.getredRecordList();
		},
		handleCurrentPageChange(page) {
			this.listInfo.page = page;
			this.getredRecordList();
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.my-point {
	background: #ffffff;
	padding: 20px;
	.member-point {
		font-size: $ns-font-size-base;
		font-weight: 600;
		margin-bottom: 10px;
		.num {
			font-size: 30px;
			font-weight: 600;
		}
	}
	.page {
		display: flex;
		justify-content: center;
		align-content: center;
		padding-top: 20px;
	}
}
</style>
